<template>
  <div class="row">
    <div class="col-md-12" v-if="loading">
      <div class="row mt-5 pt-5">
        <div class="col-md-12" v-loading="loading"></div>
      </div>
    </div>
    <div class="col-md-12" v-if="!loading">
      <el-table
        :data="tableData"
        :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
        style="width: 100%"
        :empty-text="'No Data Available'"
        height="60vh">
        <el-table-column label="End Date" width="200">
          <template slot-scope="scope">
            <p>
              {{ moment(scope.row.end_date).format("ll") }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Message" prop="message" width="">
        </el-table-column
        ><el-table-column label="Country" prop="country" width="">
        </el-table-column>
        <el-table-column label="Status" prop="status" width="200">
          <template slot-scope="scope">
            <span style="color: #36d962" v-if="scope.row.status == 1"
              >Active</span
            >
            <span style="color: #f84d4d" v-if="scope.row.status == 0"
              >Inactive</span
            >
          </template>
        </el-table-column>
        <el-table-column label="Action" width="200">
          <template slot-scope="scope">
            <button
              id="enter__account"
              class="approve"
              @click="publish(scope)"
              v-if="scope.row.status === 0">
              Publish
              <!-- {{ scope.row.status === 1 ? 'Publish' : 'Unpublish' }} -->
            </button>
            <button
              id="enter__account"
              class="approve"
              @click="unpublishNotification(scope)"
              v-else>
              Unpublish
            </button>
            <button class="text-danger" @click="deleteNotification(scope)">
              Delete
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <ActionModal ref="actionModal" :payload="payload" @close="closedModal" />
    <NotificationsModal
      ref="notificationModal"
      :payload="payload"
      @close="closedModal" />
    <ActionModal ref="unpublishModal" :payload="payload" @close="closedModal" />
  </div>
</template>

<script>
  import $ from "jquery";
  import store from "@/state/store.js";
  import moment from "moment";
  import appConfig from "@/app.config";
  import NotificationsModal from "./NoficationModal";
  import Storage from "../../../services/storage";

  var storage = new Storage();

  export default {
    components: {
      NotificationsModal,
    },
    page: {
      title: "Notification history",
      meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
      return {
        loading: false,
        payload: {
          type: "",
        },
      };
    },
    computed: {
      tableData() {
        return store.getters["admin/notifications/notifications"]
          .filter((data) => {
            return data.status == 1 || data.status == 0;
          })
          .filter((data) => {
            // check country from storage if CI then show only CI if not show everything
            if (storage.getSavedState("auth.admin")?.country == "CI") {
              return data.country == "CI";
            } else {
              return true;
            }
          });
      },
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
    methods: {
      openNotificationModal() {
        let element = this.$refs.notificationModal.$el;
        $(element).modal("show");
      },
      openActionModal() {
        let element = this.$refs.actionModal.$el;
        $(element).modal("show");
      },
      publish(data) {
        this.payload = {
          type: "publish",
          data: data.row,
        };
        this.openNotificationModal();
      },
      openUnpublishModal() {
        let element = this.$refs.unpublishModal.$el;
        $(element).modal("show");
      },

      deleteNotification(message) {
        this.payload = {
          message: "Are you sure you want to delete this notification?",
          action: "deleteNotification",
          actionMessage: "Delete",
          data: message.row,
          iconType: "delete",
          index: message.$index,
        };
        this.openActionModal();
      },
      unpublishNotification(message) {
        this.payload = {
          message: "Are you sure you want to unpublish this notification?",
          action: "unpublishNotification",
          actionMessage: "Unpublish",
          data: message.row,
          iconType: "delete",
          index: message.$index,
        };

        this.openUnpublishModal();
      },
      closedModal() {
        let element = this.$refs.unpublishModal.$el;
        $(element).modal("hide");

        let element2 = this.$refs.actionModal.$el;
        $(element2).modal("hide");

        this.loading = true;
        this.payload = {
          type: "",
        };
        store
          .dispatch("admin/notifications/getNotifications")
          .finally(() => (this.loading = false));
      },
    },
    mounted() {
      this.loading = true;
      store
        .dispatch("admin/notifications/getNotifications")
        .finally(() => (this.loading = false));
    },
  };
</script>

<style scoped>
  #enter__account {
    color: #f7921c;
  }

  button {
    outline: none;
    border: none;
    background: transparent;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
  }
</style>
